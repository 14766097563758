<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <div class="" style="text-align: center; margin-bottom: 18px"></div>
            <v-card class="elevation-12 pa-8" v-if="success">
              <v-card-text>
                <div class="d-block">
                  <div class="d-block text-center">
                    <img
                      src="../assets/logo.svg"
                      alt=""
                      class="logo-forms mr-auto my-auto"
                    />
                  </div>
                  <div class="mt-6 mb-8 text-center">
                    <p>
                      Vstupte do moderního domu jednadvacátého
                      století.
                    </p>
                  </div>
                </div>
                <div class="mb-14 text-center font-weight-bold">
                  Vaše heslo bylo úspěšně změněno. Nyní se prosím přihlaste.
                </div>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  tile
                  type="submit"
                  color="primary"
                  style="width: 100%"
                  class="mt-12"
                  @click="$router.push('/login')"
                  >Přihlásit se</v-btn
                >
              </v-card-actions>
            </v-card>

            <v-card class="elevation-12 pa-8" v-if="!success">
              <v-card-text>
                <div class="d-block">
                  <div class="d-block text-center">
                    <img
                      src="../assets/logo.svg"
                      alt=""
                      class="logo-forms mr-auto my-auto"
                    />
                  </div>
                  <div class="mt-6 mb-8 text-center">
                    <p>
                      Vstupte do moderního domu jednadvacátého
                      století.
                    </p>
                  </div>
                </div>
                <v-form
                  @submit.prevent="forgottenPassword"
                  @keyup.native.enter="forgottenPassword"
                >
                  <Spinner v-if="processing" />
                  <v-text-field
                    label="Heslo"
                    name="password"
                    type="password"
                    class="mymd-primary-dark-text"
                    v-model="$v.password.$model"
                  ></v-text-field>
                  <span
                    class="error-input"
                    :class="{
                      'error-input--active': $v.password.$anyError,
                    }"
                  >
                    {{ !$v.password.required ? "Toto pole je povinné" : "" }}
                  </span>
                  <v-text-field
                    label="Heslo znovu"
                    name="password"
                    type="password"
                    v-model="$v.passwordAgain.$model"
                  ></v-text-field>
                  <span
                    class="error-input"
                    :class="{
                      'error-input--active': $v.passwordAgain.$anyError,
                    }"
                  >
                    {{
                      !$v.passwordAgain.required
                        ? "Toto pole je povinné"
                        : "Hesla se neshodují"
                    }}
                  </span>
                  <div style="text-align: center"></div>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  tile
                  type="submit"
                  color="primary"
                  style="width: 100%"
                  class="mt-12"
                  @click="forgottenPassword"
                  >Změnit</v-btn
                >
              </v-card-actions>
              <div style="text-align: center" class="mt-8">
                <button
                  type="button"
                  @keydown.enter.prevent="() => {}"
                  @click.stop="
                    () =>
                      $router
                        .push({
                          name: 'Login',
                        })
                        .catch(() => {})
                  "
                  class="mymd-primary-dark-text"
                  style="font-size: 12px; z-index: 999"
                >
                  Zrušit
                </button>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
/* eslint-disable */
import { validationMixin } from "vuelidate";
import { required, sameAs } from "vuelidate/lib/validators";
import Auth from "../api/authService";
import Spinner from "../components/Spinner";
import bus from "../plugins/bus";

export default {
  mixins: [validationMixin],

  validations: {
    password: { required },
    passwordAgain: { required, sameAs: sameAs("password") },
  },
  name: "ForgottenPasswordCode",
  components: {
    Spinner,
  },
  computed: {},
  data() {
    return {
      password: "",
      passwordAgain: "",
      processing: false,
      success: false,
      message: "",
    };
  },
  methods: {
    async forgottenPassword() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        this.processing = true;
        const r = await Auth.forgottenPasswordLink(
          this.$route.params.forgottenPasswordCode,
          this.password
        );
        this.message = r.message;
        this.processing = false;
        this.success = true;
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error.message
        ) {
          bus.$emit("snackbar", {
            text: error.response.data.error.message,
            color: "error",
          });
        }
        this.processing = false;
      }
    },
  },
};
</script>
